import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export type LoginUserInfoSlicerState = {
  userInfo: {
    user_id: string,
    email: string,
    family_name: string,
    name: string,
    family_name_kana: string,
    name_kana: string,
    is_isp_owner: boolean,
  },
};

const initialState: LoginUserInfoSlicerState = {
  userInfo: {
    user_id: '',
    email: '',
    family_name: '',
    name: '',
    family_name_kana: '',
    name_kana: '',
    is_isp_owner: false,
  },
};

export const loginIspUserInfoSlice = createSlice({
  name: 'login-isp-user-info',
  initialState,
  reducers: {
    setIspUserDetail: (state, action: PayloadAction<{
      userInfo: {
        user_id: string,
        email: string,
        family_name: string,
        name: string,
        family_name_kana: string,
        name_kana: string,
        is_isp_owner: boolean,
      },
    }>) => {
      state.userInfo = action.payload.userInfo;
    },
  },
});

export const loginIspUserInfoAction = loginIspUserInfoSlice.actions;
export const loginIspUserInfoReducer = loginIspUserInfoSlice.reducer;
